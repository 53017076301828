import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Validators } from '@angular/forms';
import { expiredDate } from '@modules/controls/validators/date.validator';
import { AvailableStrategyField } from '@type/strategy.type';
import { map } from 'rxjs';
import { AvailableFieldFormService } from '../available-field-form/available-field-form.service';
import { StrategyForm, StrategyOptions } from '../types';

@Injectable()
export class StrategyFormService extends AvailableFieldFormService<StrategyForm, StrategyOptions> {
  options = {
    storeOrClusterList: () => [
      { name: this.translate.t('stores'), id: 'stores' },
      { name: this.translate.t('clusters'), id: 'clusters' },
    ],
    strategyBaseOnList: () => [{ name: this.translate.t('matrix'), id: 'matrix' }],
  };

  form = this.fb.group({
    categoryId: this.fb.control(this.category.value()?.id || 0),
    strategyFor: this.fb.control(this.options.storeOrClusterList()[0].id, [Validators.required]),
    name: this.fb.control<string>('', [Validators.required]),
    startDate: this.fb.control<string>('', [expiredDate, Validators.required]),
    strategyBaseOn: this.fb.control(this.options.strategyBaseOnList()[0], [Validators.required]),
    onDate: this.fb.control<string>('', [Validators.required]),
    statuses: this.fb.control<number[]>([], [Validators.required]),
    selectedProps: this.fb.control<AvailableStrategyField[]>(
      [],
      [Validators.required, Validators.minLength(1), Validators.maxLength(5)],
    ),
  });

  isDisabled = toSignal(this.form.controls.strategyFor.statusChanges.pipe(map((status) => status === 'DISABLED')), {
    initialValue: false,
  });

  resetForm(): void {
    this.form.reset({
      categoryId: this.category.value()?.id || 0,
      strategyFor: this.options.storeOrClusterList()[0].id,
      name: '',
      startDate: '',
      onDate: '',
      statuses: [],
      strategyBaseOn: this.options.strategyBaseOnList()[0],
      selectedProps: [],
    });
  }
}
