import { Provider, Type } from '@angular/core';
import { AvailableFieldFormService } from './available-field-form/available-field-form.service';
import { AvailableFieldService } from './available-field/available-field.service';

export function withAvailableFields(Klass: Type<AvailableFieldService>): Provider {
  return {
    provide: AvailableFieldService,
    useClass: Klass,
  };
}

export function withAvailableFieldForm<T>(Klass: Type<T>): Provider {
  return {
    provide: AvailableFieldFormService,
    useClass: Klass,
  };
}
