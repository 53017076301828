import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { joinUrls } from '@utils/urls';
import { AvailableFieldService } from '../available-field/available-field.service';

@Injectable()
export class StrategyFieldsService extends AvailableFieldService {
  url(id: number | string) {
    const { categories, strategies, available_fields } = environment.urls;
    return joinUrls(categories, String(id), strategies, available_fields);
  }
}
